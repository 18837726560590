import { theme as proTheme } from '@chakra-ui/pro-theme'
import { theme as baseTheme } from "@chakra-ui/react";
import { extendTheme  } from '@chakra-ui/react'

const colors = {
   
    background: '#FFFFFF',
    surface: '#242526',
    wash: '#18191a',
    muted: '#f0e6f6',
    text: '#e4e6eb',

    slate: '#2f4f4ff7',

    ss_green: '#005C30',
    green: '#005C30',
    ss_green_old: '#0CAA56',
    
    primary: '#000',
    secondary: '#0CAA56',
    highlight: '#0CAA56',

}

const queries = {
    xs: '@media screen and (max-width: 40em)',
    sm: '@media screen and (min-width: 40em) and (max-width: 52em)',
    'sm+': '@media screen and (min-width: 40em)',
    md: '@media screen and (min-width: 52em) and (max-width: 64em)',
    'md+': '@media screen and (min-width: 52em)',
    lg: '@media screen and (min-width: 64em)',
  }
  
export const theme = extendTheme(
  {
    ...baseTheme,
    colors: { 
      ...colors ,
      ...baseTheme.colors,
      green: {
        50: '#f0fdf4',
        100: '#c6f6d5',
        200: '#9ae6b4',
        300: '#68d391',
        400: '#48bb78',
        500: '#005C30', // This is the default color used when `colorScheme="green"`
        600: '#2f855a',
        700: '#276749',
        800: '#22543d',
        900: '#1c4532',
        // Add other custom colors or modify existing ones
      }, 
      brand: {
        50: '#f0fdf4',
        100: '#c6f6d5',
        200: '#9ae6b4',
        300: '#68d391',
        400: '#48bb78',
        500: '#005C30', // This is the default color used when `colorScheme="green"`
        600: '#2f855a',
        700: '#276749',
        800: '#22543d',
        900: '#1c4532',
      }
    },
  },
  queries,
  proTheme,
)