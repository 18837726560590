import { createRoot } from 'react-dom/client';

import {Amplify} from "aws-amplify";

import './index.css';
import App from './App.tsx';
import * as serviceWorker from "./serviceWorker.js";
import reportWebVitals from './reportWebVitals';
import awsExports from "./aws-exports";

import ErrorBoundary from "./pages/ErrorBoundary"

Amplify.configure(awsExports);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ErrorBoundary>
    <App tab="home" />
  </ErrorBoundary>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          
            window.location.reload()
          
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  },
});