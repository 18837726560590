/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
        "aws_project_region": "us-west-2",
        //"aws_cognito_identity_pool_id": "us-west-2:22caf10f-8766-4e80-81f0-1a2d58b302ca",
        "aws_cognito_region": "us-west-2",
        "aws_user_pools_id": "us-west-2_30S2OiWxy",
        "aws_user_pools_web_client_id": "9kg884inaj8hfb6lofaq6uqi5",
        "authenticationFlowType": "USER_SRP_AUTH",
        "oauth": {}

};

export default awsmobile;
