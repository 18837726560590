import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import LogRocket from 'logrocket';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENTID;
const REDIRECT_URI = `${process.env.REACT_APP_HOST}/login`; // e.g., http://localhost:3001
  
const SCOPES = 'https://www.googleapis.com/auth/admin.directory.group.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';

interface User {
  name: string;
  email: string;
  imageUrl: string;
  accessToken: string;
}

const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [groups, setGroups] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const decodedUser = JSON.parse(storedUser);
      setUser(JSON.parse(storedUser));
      // Identify the user in Log Rocket
      LogRocket.identify(decodedUser.email, {
        name: decodedUser.name,
        email: decodedUser.email,
      
        // Add your own custom user variables here, ie:
        subscriptionType: 'staff'
      });
    }
    
    setLoading(false);

    const handleAuthorizationCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const codeVerifier = sessionStorage.getItem('code_verifier');

      if (code && codeVerifier) {
        try {
          const response = await axios.post('https://oauth2.googleapis.com/token', {
            client_id: CLIENT_ID,
            client_secret:  process.env.REACT_APP_GOOGLE_CLIENT_SECERT,
            redirect_uri: REDIRECT_URI,
            grant_type: 'authorization_code',
            code,
            code_verifier: codeVerifier,
          });

          const { access_token, id_token, expires_in, refresh_token  } = response.data;
          const expirationTime = Date.now() + expires_in * 1000;

          // Fetch user info
          const userInfo = await fetchUserInfo(access_token);
          await fetchUserGroups(access_token, userInfo.email);

          const user_info = {
            id: userInfo.id,
            name: userInfo.name,
            email: userInfo.email,
            imageUrl: userInfo.picture,
            accessToken: access_token,
            idToken: id_token, // Include id_token here
            refreshToken: refresh_token, // Store the refresh token
            expirationTime: expirationTime, // Store the expiration time
          };
          setUser(user_info);
          localStorage.setItem('user', JSON.stringify(user_info));
          
          // Identify the user in Log Rocket
          LogRocket.identify(userInfo.email, {
            name: userInfo.name,
            email: userInfo.email,
          
            // Add your own custom user variables here, ie:
            subscriptionType: 'staff'
          });

          // Remove the code verifier from session storage
          sessionStorage.removeItem('code_verifier');

          // Store a flag in local storage to indicate the user has given consent
          localStorage.setItem('user_consent', 'true');

          // Redirect to the original page or home
          const redirectTo = sessionStorage.getItem('redirect_to') || '/';
          sessionStorage.removeItem('redirect_to');
          navigate(redirectTo);

        } catch (error) {
          console.error('Error exchanging authorization code for access token:', error);
        }
      }

      setLoading(false);
    };

    handleAuthorizationCode();
  }, [navigate]);

  const fetchUserInfo = async (accessToken: string) => {
    try {
      const response = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user info:', error);
      throw error;
    }
  };

  const fetchUserGroups = async (accessToken: string, email: string) => {
    try {
      const response = await axios.get(
        `https://admin.googleapis.com/admin/directory/v1/groups?userKey=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log('User groups:', response.data);
      const data = response.data;
      setGroups(data.groups || []);
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  const login = async () => {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    sessionStorage.setItem('code_verifier', codeVerifier);

    const prompt = 'consent';

     const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${encodeURIComponent(SCOPES)}&access_type=offline&prompt=${prompt}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    window.location.href = authUrl;
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    window.google?.accounts.id.disableAutoSelect();
    navigate('/login');
  };

  const refreshToken = async (refreshToken) => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        client_id: CLIENT_ID,
        client_secret: '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',
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      });
  
      const { access_token, id_token, expires_in } = response.data;
      const expirationTime = Date.now() + expires_in * 1000;
  
      // Update the user object with new tokens and expiration time
      const user_info = JSON.parse(localStorage.getItem('user'));
      user_info.accessToken = access_token;
      user_info.idToken = id_token;
      user_info.expirationTime = expirationTime;
  
      setUser(user_info);
      localStorage.setItem('user', JSON.stringify(user_info));
  
      return access_token;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };
  
  return {
    user,
    loading,
    login,
    logout,
  };
};

function generateCodeVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

function base64UrlEncode(str: string) {
  return btoa(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

async function generateCodeChallenge(codeVerifier: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  return base64UrlEncode(String.fromCharCode(...Array.from(new Uint8Array(digest))));
}



export default useAuth;
