import { Suspense, lazy } from 'react'

import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  RecoilRoot,
} from 'recoil';

import LogRocket from 'logrocket';

import PrivateRoute from './components/PrivateRoute';

import {theme} from './theme';
import './App.css';

import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import { ChakraProvider } from '@chakra-ui/react'
 
import useNetwork from "./hooks/useNetwork";

import Loading from './pages/Loading'

import "@fontsource/roboto";

const Login = lazy(() => import('./pages/Login'));

const Layout = lazy(() => import('./components/Layout/Layout'));

// Public Stat pagges
const Monitor = lazy(() => import('./pages/monitor/index'));
// semi private kiosk pages 
const Kiosk = lazy(() => import('./pages/kiosk/index'));

const TestError = lazy(() => import('./pages/TestError'));

const queryClient = new QueryClient({
   defaultOptions: {
     queries: {
       cacheTime: 1000 * 60 * 60 * 24, // 24 hours
       staleTime: 1000* 60,
     },
   },
});
 
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})



function App() {

  LogRocket.init(
    'kua04w/sportstats',
    {
      dom: {
        textSanitizer: true,
        inputSanitizer: true,
      },
  }
  );

  return (

      <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-right' } }}>

            <RecoilRoot>

                <PersistQueryClientProvider
                  client={queryClient}
                  persistOptions={{ persister }}
                >
                  
                    <BrowserRouter>

                      <Suspense fallback={<Loading />}>
                        <Routes>

                          <Route path="/*" element={<PrivateRoute path="/*"  > <Layout/> </PrivateRoute>} />
                 
                          <Route path="/login" element={ <Login/>}  /> 

                          <Route path="/monitor/:eid" element={ <Monitor/>}  /> 
                          <Route path="/kiosk/:eid/*" element={ <Kiosk/>}  /> 

                          <Route path="/test-error" element={ <TestError/>}  /> 

                        </Routes>
                        
                      </Suspense>

                    </BrowserRouter>

                </PersistQueryClientProvider>
         
            </RecoilRoot>

      </ChakraProvider>
  
  );
}

export default App;